import awsmobileDev from '../backend/aws-exports-dev';
import awsmobileProd from '../backend/aws-exports-prod';

export const getAwsExport = () => {
  const environment = 'production'
  let awsmobile;
  switch (environment) {
    case 'production':
      awsmobile = awsmobileProd;
      break;
    default:
      awsmobile = awsmobileDev;
      break;
  }
  return awsmobile;
};
